<template>
  <div class="ordinaryDown">
    <div class="ordinary-head">
      <!-- <img class="headBg" src="@/assets/downLoad/headBg.png" alt=""> -->
      <!-- <img @click="downEvent" class="downloadNow" src="@/assets/downLoad/downloadNow.png" alt=""> -->
      <div class="open-app" @click="openApp"></div>
    </div>
    <div class="ord-content">
      <div class="ord-cont-head">
        <!-- <img class="textImg" src="@/assets/downLoad/text.png" alt=""> -->
      </div>
      <div class="ord-item" v-for="(item, index) in imgData" :key="index">
        <img :src="item" alt="">
      </div>
    </div>
    <div style="height: 75px;"></div>
    <pagetips ref="pagetips"></pagetips>
  </div>
</template>

<script>
import pagetips from '../commonModule/pagetips'
export default {
  name: 'ordinaryDown',
  components: {
    pagetips
  },
  data () {
    return {
      imgData: [],
      downurls: '',
      type: 1
    }
  },
  // watch: {
  //   '$store.state.deviceType'(val) {
  //     alert(val);
  //     let type = val == 1 ? 2 : 1;
  //     this.getNewVersionEvent(type);
  //   }
  // },
  mounted () {
    console.log(wx, 'wx')
    // alert(this.$store.state.deviceType)
    const type = this.$store.state.deviceType == 1 ? 2 : 1
    this.type = this.$store.state.deviceType
    if (this.$store.state.deviceType < 4) {
      this.getNewVersionEvent(type)
    } else {
      this.downurls = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.czh.zhxt'
      // console.log(this.$refs.pagetips);
      // this.$refs.pagetips.showEvent();
    }
  },
  methods: {
    // getShareInfo() {
    //   wx.config({
    //       debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //       appId: res.data.appId, // 必填，公众号的唯一标识
    //       timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
    //       nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    //       signature: res.data.signature, // 必填，签名
    //       jsApiList: [
    //         "updateAppMessageShareData",
    //         "updateTimelineShareData"
    //       ] // 必填，需要使用的 JS 接口列表
    //     });
    //     wx.ready(() => {
    //       var shareData = {
    //         title: "每日新闻",
    //         desc: "2022年12月20日21:47:55每日新闻",
    //         link: window.location.href,
    //         imgUrl: "https://blogobs.88688.team/blog/l-logo-y.jpg"
    //       };
    //       //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
    //       wx.updateAppMessageShareData(shareData);
    //       //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
    //       wx.updateTimelineShareData(shareData);
    //     });
    //     //错误了会走 这里
    //     wx.error(function (res) {
    //       console.log("微信分享错误信息", res);
    //     });
    // },
    getNewVersionEvent (type) {
      this.$request.getNewVersion({ type }).then(res => {
        if (res.data.code == 0) {
          this.downurls = res.data.data.downurls
        }
      })
    },
    downEvent () {
      if (!this.downurls) {
        this.Info('暂时无法下载')
        return
      }
      // window.location.href = this.downurls;
      window.location.replace(this.downurls)
    },
    openApp () {
      // alert(this.type)
      if (this.type == 1) {
        window.location.href = 'com.czh.zhxt.app://'
      } else if (this.type == 2) {
        window.location.href = 'android://com.czh.zhxt/open'
      } else if (this.type == 4 || this.type == 5 || this.type == 6 || this.type == 7) {
        // this.Info('选择浏览器打开该页面,再进行app打开');
        this.$refs.pagetips.showEvent()
      }
    }
  }
}
</script>

<style lang='scss'>
  .ordinaryDown {
    height: 100%;
    overflow: scroll;
    .ordinary-head {
      width: 100%;
      position: relative;
      .headBg {
        width: 100%;
      }
      .downloadNow {
        width: 253px;
        position: absolute;
        left: 50%;
        top: 286px;
        opacity: 0;
        transform: translateX(-50%);
        animation: btnmove 1s forwards;
        -webkit-animation: btnmove 1s forwards; /* Safari and Chrome */
      }
      .open-app {
        position: absolute;
        left: 50%;
        bottom: 70px;
        transform: translateX(-50%);
        width: 100px;
        height: 20px;
        background-color: transparent;
      }
    }
    .ord-content {
      width: 100%;
      padding-bottom: 75px;
      .ord-cont-head {
        width: 100%;
        text-align: center;
        margin-top: 47px;
        .textImg {
          width: 178px;
          height: 66px;
        }
      }
    }
    .ord-item {
      // height: 65px;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      opacity: 0;
      animation: myfirst 2s forwards;
      -webkit-animation: myfirst 2s forwards; /* Safari and Chrome */
      padding: 0 20px;
      box-sizing: border-box;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .ord-item:nth-of-type(2) {
      animation-delay: 0.5s;
	    -webkit-animation-delay: 0.5s;
    }
    .ord-item:nth-of-type(3) {
      animation-delay: 1s;
	    -webkit-animation-delay: 1s;
    }
    .ord-item:nth-of-type(4) {
      animation-delay: 1.5s;
	    -webkit-animation-delay: 1.5s;
    }
    .ord-item:nth-of-type(5) {
      animation-delay: 2s;
	    -webkit-animation-delay: 2s;
    }
    .ord-item:nth-of-type(6) {
      // background-color: #000;
      animation-delay: 2.5s;
	    -webkit-animation-delay: 2.5s;
    }
    .ord-item:nth-of-type(7) {
      // background-color: #000;
      animation-delay: 3s;
	    -webkit-animation-delay: 3s;
    }
    @keyframes btnmove
    {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes btnmove
    {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes myfirst
    {
      0% {
        opacity: 0;
        // padding-left: 10px;
      }
      100% {
        opacity: 1;
        // padding-left: 45px;
      }
    }

    @-webkit-keyframes myfirst
    {
      0% {
        opacity: 0;
        // -webkit-padding-left: 10px;
      }
      100% {
        opacity: 1;
        // -webkit-padding-left: 45px;
      }
    }
  }
</style>
